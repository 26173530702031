/* Ensure the default Bootstrap carousel controls are hidden */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

/* Style the Font Awesome icons */
.carousel-control-prev .fa-arrow-circle-left,
.carousel-control-next .fa-arrow-circle-right {
  color: black;
  font-size: 2rem; /* Adjust the size as needed */
}

.carousel-control-prev,
.carousel-control-next {
  background: none;
  border: none;
  outline: none;
}

/* Ensure images are responsive */
.carousel-item img {
  width: 100%;
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) {

  .carousel-item img {
    object-fit: cover;
    height: 100%;
  }
  .carousel-control-prev .fa-arrow-circle-left,
    .carousel-control-next .fa-arrow-circle-right {
  color: black;
  font-size: 1rem; /* Adjust the size as needed */
}
}



  
  