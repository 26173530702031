


.medjust {
    max-width: 1000px; /* Adjust the max-width as needed */
    width: 60%;
    padding: 20px;
    background-color: #ffffff; /* Optional: Add background color */
    border-radius: 10px;
    margin: auto; /* Center horizontally */
}

.mh1 {
    margin-top: 4rem;
    margin-bottom: 2rem;
    color: #d92121;
    font-size: 2.5rem;
    font-weight: 600;
}

.second {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    color: rgb(31, 31, 73);
    font-size: 1.5rem;
}

.medimg {
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    height: auto;
    margin: 20px 0;
    border-radius: 10px;
}

.Medp {
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 10px;
    font-weight: 400;
    text-align: left;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .mh1 {
        margin-top: 2rem;
        font-size: 2rem;
    }
    .medjust{
    width: 100%;
    }

    .medimg {
        max-width: 300px;
    }

    .second {
        font-size: 1.25rem;
    }

    .Medp {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .mh1 {
        font-size: 1.5rem;
    }

    .second {
        font-size: 1rem;
    }
    .medjust{
        width: 100%;
        }    

    .Medp {
        font-size: 0.875rem;
    }
}
