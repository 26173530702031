* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    align-items: center;
}
.mice-container {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: auto;
}

.head1 {
    margin-top: 100px;
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-right: 20px;
    color: red;
}

.heading3 {
    margin-top: 100px;
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-right: 20px;
}

.bigline {
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 80px;
    margin-right: 20px;
}

.img {
    width: 33%;
    height: 30dvh;
    margin-top: 20px;
}

.hero {
    width: 100%;
    position: relative;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 20px 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
}

nav .logo {
    width: 200px;
}

nav ul {
    display: flex;
    list-style: none;
}

nav ul li {
    margin-left: 40px;
}

nav ul li:first-child {
    margin-left: 0;
}

nav ul li a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
    .head1, .heading3, .bigline {
        font-size: 35px;
    }
    nav ul li {
        margin-left: 30px;
    }
    nav ul li a {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .head1, .heading3, .bigline {
        font-size: 30px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .img {
        height: 50dvh;
    }
    nav {
        padding: 15px 5%;
    }
    nav ul li {
        margin-left: 20px;
    }
}

@media (max-width: 768px) {
    .head1, .heading3, .bigline {
        font-size: 25px;
        margin-top: 20px;
    }
    .hero {
        flex-direction: column;
        align-items: flex-start;
    }
    nav ul {
        flex-direction: column;
        align-items: flex-start;
    }
    nav ul li {
        margin-left: 0;
        margin-bottom: 10px;
    }
    .img {
        width: 33.33%;
        height: 30vh;
      }
}

@media (max-width: 576px) {
    .head1, .heading3, .bigline {
        font-size: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .img {
        height: 40dvh;
    }
    nav .logo {
        width: 150px;
    }
    nav {
        padding: 10px 2%;
    }
    nav ul li a {
        font-size: 14px;
    }
    .head1{
        margin-top: 50px;
    }
    .heading3{
        margin-top: 40px;
    }
}
@media (max-width: 480px) {
    .img {
    width: 33.33%;
    height: 15vh;
    }
  }