.footer {
    padding-top: 50px;
    padding-bottom: 30px;
    background: #cae5f4;
}

.footer .pogo img {
    width: 100%;
    margin-bottom: -100px;
    margin-top: -80px;
    margin-left: -50px;
}

.footer .pogo p {
    color: #333;
    font-weight: bold;
    width: 450px;
}

.social__links span a {
    text-decoration: none;
    color: #333;
    font-size: 1.2rem;
    font-weight: bold;
}

.footer__link-title {
    color: #333;
    margin-bottom: 1rem;
    font-weight: bold;
    margin-left: 10rem;
}

.footer__link-maintitle {
    color: #333;
    margin-bottom: 1rem;
    font-weight: bold;
}

.footer__quick-links .footer__link-item {
    margin-bottom: 1.5rem;
    margin-left: 10rem;
}

.footer__quick-links .footer__link-item a {
    text-decoration: none;
    color: #333;
    font-size: 1.1rem;
    font-weight: bold;
    transition: color 0.3s;
}

.footer__quick-links .footer__link-item a:hover {
    color: #007bff;
}

.footer__quick-links .footer__contact-item {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 0.5rem;
}

.footer__quick-links .footer__contact-item h6 {
    font-weight: bold;
    margin: 0;
}

.footer__quick-links .footer__contact-item p {
    margin: 0;
}

.footer__quick-links .footer__contact-item span i {
    color: #007bff;
    font-size: 1.1rem;
    font-weight: bold;
}
.quick-link-images {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
  }
  
  .quick-link-image {
    max-width: 100px;
    height: auto;
    margin-top: 4.5rem;
  }
  .quick-link-image1 {
    max-width: 100px;
    height: auto;
  }
  

.d-flex.justify-content-center.gap-2 .quick-link-image {
  margin-right: 10px; /* Adjust the gap value as needed */
}

.d-flex.justify-content-center.gap-2 .quick-link-image:last-child {
  margin-right: 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 992px) {
    .footer__link-title, .footer__quick-links .footer__link-item {
        margin-left: 5rem;
    }
    .footer .pogo p {
        color: #333;
        font-weight: bold;
        width: 450px;
    }
    .quick-link-image {
        display: none;
        max-width: 100px;
        height: auto;
        margin-top: 4.5rem;
      }
      .quick-link-image1 {
        display: none;
        max-width: 100px;
        height: auto;
      }
      
    
    .d-flex.justify-content-center.gap-2 .quick-link-image {
      margin-right: 10px; /* Adjust the gap value as needed */
    }
    
    .d-flex.justify-content-center.gap-2 .quick-link-image:last-child {
      margin-right: 0;
    }
}

@media (max-width: 768px) {
    .footer__link-title, .footer__quick-links .footer__link-item {
        margin-left: 2rem;
    }
    .footer .pogo img {
        width: 60%;
        
        
    }
    .footer .pogo p {
        color: #333;
        font-weight: bold;
        width: 450px;
    }
}

@media (max-width: 576px) {
    .footer {
        padding: 20px 10px;
    }
    .footer .pogo img {
        width: 100%;
        margin-left: -45px;
        margin-top: -50px;
        margin-bottom: -55px;
    }
    .footer__link-title, .footer__quick-links .footer__link-item {
        margin-left: 0;
    }
    .footer__quick-links .footer__link-item, .footer__quick-links .footer__contact-item {
        display: block;
    }
    .footer__quick-links .footer__contact-item {
        justify-content: center;
    }
    .footer .pogo p {
        color: #333;
        font-weight: bold;
        width: 250px;
    }
}
@media (max-width: 1024px) and (min-width: 769px) {
    .footer .pogo img {
        width: 100%;
        margin-left: -40px;
        margin-top: -50px;
        margin-bottom: -55px;
    }
    .quick-link-image {
        display: none;
        max-width: 100px;
        height: auto;
        margin-top: 4.5rem;
      }
      .quick-link-image1 {
        display: none;
        max-width: 100px;
        height: auto;
      }
      
    
    .d-flex.justify-content-center.gap-2 .quick-link-image {
      margin-right: 10px; /* Adjust the gap value as needed */
    }
    
    .d-flex.justify-content-center.gap-2 .quick-link-image:last-child {
      margin-right: 0;
    }
}

