.header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
}

.logo img {
    max-width: 100px;
}

.menu {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
}



.nav__item a {
    text-decoration: none;
    color: var(--heading-color);
    font-weight: 500;
    display: block;
    transition: color 0.3s ease-in-out;
}

.nav__item a:hover {
    color: var(--primary-color);
}

.nav__item a.active__link {
    color: var(--secondary-color);
}

.nav_right {
    display: flex;
    align-items: center;
}

.nav_btns .btn {
    font-size: 1rem;
}

.mobile__menu {
    font-size: 1.6rem;
    color: var(--heading-color);
    display: none;
}

.sticky__header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 3px 3px 8px -3px #ddd;
    z-index: 999;
}

/* Dropdown Styles */
.dropdown-menu {
    background-color: #fff;
    border-radius: 0;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
}

.dropdown-item {
    padding: 10px 20px;
    color: var(--heading-color);
    font-size: 1rem;
    transition: background-color 0.3s ease-in-out;
}

.dropdown-item:hover {
    background-color: var(--primary-color);
    color: #fff;
}

/* ==============make it responsive============= */
@media only screen and (max-width:1399px) {
    

    .navigation{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #0000005d;
        z-index: 999;
        display: none;
    }
    .menu{
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 55%);
        height: 100%;
        background: #fff;
        flex-direction: column;
        line-height: 0.5rem;
        justify-content: center;
        z-index: 9999;
    }

    .show__menu{
        display: block;
    }

    .mobile__menu{
        display: block;
    }
    
}

@media only screen and (max-width: 768px){
    .menu {
        width: calc(100%-50%);
    }
    
}
