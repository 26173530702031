.back-video {
    width: 100%;
    object-fit: cover;
}
.service__subtitle {
    font-size: 2.2rem;
    font-weight: 500;
    color: #ee6e6e;
    font-family: var(--subtitle-font-name);
}
.service__title {
    font-size: 2.5rem;
    font-weight: 500;
}
.featured__tour-title {
    font-size: 2.3rem;
    font-weight: 500;
}

.experience__content h2 {
    font-size: 2.5rem;
    margin-top: 1rem;
    font-weight: 500;
}
.experience__content p {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-top: 1rem;
}
.counter___wrapper{
    margin-top: 2.5rem;
}
.counter__box span{
    width: 70px;
    height: 70px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 10px 5px 10px 5px;
}

.counter__box h6{
    font-size: 1.1rem;
    margin-top: 0.7rem;
    color: var( --text-color);
}
.experinece__img img{
    width: 90%;
}
.gallery__title{
    font-size: 2.3rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
}
.masonry__img{
    transition: .3s;
}

.masonry__img:hover{
    transform: scale(1.1);
}
.testimonial__title{
    font-size: 2.3rem;
    margin-top: 1rem;
}
@media only screen and (max-width:992px){
    .service__title, .featured__tour-title, .gallery__title,.testimonial__title,
    .experience__content h2{
        font-size: 2rem;
    }
    .experinece__img{
        margin-top: 2rem;
    }
}
@media only screen and (max-width: 576px){
    .service__title, .featured__tour-title, .gallery__title,.testimonial__title,
    .experience__content h2 {
        font-size: 1.5rem;
    }
}