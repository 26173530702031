.Edu-trip{
  max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: auto;
}
.Education {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-left: 1rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
  
  .Education2 {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .Education3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .Education4 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .days {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .daysp, h4 {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-left: 1rem;
  }
  
  .daysk {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 1rem;
    margin-left: 1rem;
    
  }
  
  ul {
    list-style-type: disc;
    margin-left: 2rem;

  }
  
  @media (max-width: 768px) {
    .Education {
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .Education2 {
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .Education3 {
      font-size: 1.2rem;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .Education4 {
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .days {
      font-size: 1.2rem;
    }
  
    .daysp {
      font-size: 1rem;
    }
  
    .daysk {
      font-size: 1rem;
    }
  }
  