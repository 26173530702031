.Groupvisa {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
}

.Groupvisa h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}
.Groupvisa p{
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 1.5;
    letter-spacing: 0.1em;
    font-style: italic;
    font-size: 30px;

}



.Groupvisa .visaScreen {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

/* Responsive Adjustments */
@media (max-width: 768px) {

    .Groupvisa p {
        font-size: 1.25em; /* Adjusted paragraph size for smaller screens */
    }

    
}

@media (max-width: 480px) {
    .Groupvisa h1 {
        font-size: 1.5em; /* Further scaling down for mobile screens */
    }

    .Groupvisa p {
        font-size: 1.1em; /* Smaller font size for mobile */
    }

    
}