/* Aboutus.css */

.aboutus-headings {
    text-align: center;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .silklogos {
    width: 80%;
    height: auto;
  }
  
  .logoss {
    width: 20%;
    max-width: 100px;
  }
  
  .headdings {
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    color: #333;
    margin: 15px auto;
    line-height: 1.5;
    max-width: 800px;
    text-align: center;
  }
  
  .Screens {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    display: block;
    margin: 20px auto;
  }
  
  
  
  /* Media Queries for better responsiveness */
  @media (min-width: 768px) {
    .silklogos {
      width: 60%;
    }
  
    .logoss {
      width: 10%;
      max-width: 120px;
    }
  
    .headdings {
      font-size: 20px;
    }
  
    .Screens{
      max-width: 800px;
    }
  }
  
  @media (min-width: 992px) {
    .silklogos {
      width: 40%;
    }
  
    .logoss{
      width: 8%;
    }
  
    .headdings {
      font-size: 22px;
    }
  }
  
